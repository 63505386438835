class MortgageCalculation {
  expectedFinalPrice = 0;
  householdIncome = 0;
  downpayment = 0;
  mortgage = 0;
  interestRate = 0.0;
  calculatedInterestCost = 0;
  amortization = 0;
  operatingCost = 0;
  monthlyCost = 0;
  totalCost = 0;
}

export default MortgageCalculation;
