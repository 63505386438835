<script>
  import { onMount, onDestroy } from 'svelte';
  import { defaultFormatter, percentageWithoutFractionsFormatter } from '../utils/formatters';

  export let id, min, max, value, actualValue, step, label, labelSubtext = undefined, percentageInfo = undefined, valueFormatter = defaultFormatter, valueSuffix = '', usePollingValue = false, showDebug = false;

  $: selectedPercentage = Math.max(((actualValue - min) / (max - min)) * 100, 1);

  let element;
  let elementValue;

  let intervalHandle;
  onMount(() => {
    if(usePollingValue) {
      intervalHandle = setInterval(() => {
        elementValue = parseFloat(element?.value);
        if(value !== elementValue) {
          value = elementValue;
        }
      }, 50);
    }
  });

  onDestroy(() => {
    if(!!intervalHandle) {
      clearInterval(intervalHandle);
      intervalHandle = undefined;
    }
  });

  $: labelTitle = showDebug ? JSON.stringify({
      id,
      min,
      max,
      value,
      actualValue,
      step,
      label,
      labelSubtext,
      percentageInfo,
      valueFormatter,
      valueSuffix,
      selectedPercentage,
      inputValue: elementValue
    }, null, 2) : label;

</script>

<div class="form-group">
  <div class="row">
    <label for={id} title={labelTitle}>
      <span>
        {label}
      </span>
      {#if !!labelSubtext}
        <span class="subtext">{labelSubtext}</span>
      {/if}
      {#if typeof percentageInfo !== 'undefined'}
        <span class="percentage-info">{percentageWithoutFractionsFormatter(percentageInfo)}</span>
      {/if}
    </label>

    <span class="value">{valueFormatter(actualValue)}{valueSuffix}</span>
  </div>
  {#if usePollingValue}
    <input
      type="range"
      id={id}
      min={min}
      max={max}
      step={step}
      style="background-size: {selectedPercentage}% 100%"
      bind:this={element}
      value={value}>
    {:else}
      <input
        type="range"
        id={id}
        min={min}
        max={max}
        step={step}
        style="background-size: {selectedPercentage}% 100%"
        bind:this={element}
        bind:value={value}>
    {/if}
</div>

<style lang="scss">
  .form-group {
    margin-bottom: 42px;

    > * {
      display: block;
      margin-bottom: 0.5em;
    }

    > .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 24px;
    }

    .percentage-info {
      display: inline-block;
      margin-left: 16px;
    }


    $track-background-color: #ddd;

    label {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 3px;

      font-size: $font-size-slider-label;

      .percentage-info {
        font-weight: 700;
      }
    }

    .value {
      font-size: $font-size-slider-value;
    }

    input {
      width: 100%;
      height: 6px;
      border-radius: 3px;
      -webkit-appearance: none;
      background: linear-gradient($primary-color, $primary-color) no-repeat content-box, linear-gradient($track-background-color, $track-background-color) content-box;
      border: 0;
      padding: 0;
      cursor: pointer;
      margin-top: 12px;

      // 2022-09-17 KJ These two sadly needs to be separate declarations, otherwise
      // Chrome won't apply the styles for some reason.
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid $primary-color;
        cursor: pointer;
      }

      &::-moz-range-thumb {
        -webkit-appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid $primary-color;
        cursor: pointer;
      }
    }
  }
</style>
