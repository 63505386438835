<script>
  import { onDestroy, tick } from 'svelte';
  import { lock, unlock } from '../utils/scroll-utils';
  import Loader from './Loader.svelte';
  import PartnerLead from '../models/partner-lead';
  import MortgageCalculation from '../models/mortgage-calculation';
  import ApiClient from '../services/api-client';

  /**
   * @type {boolean}
   */
  export let showDialog, privacyPolicyUrl = '/', basePath = '/';

  /**
   * @type {MortgageCalculation}
   */
  export let mortgageCalculation = new MortgageCalculation(), organization = '', organizationUnit = '';

  let givenName, familyName, email, phoneNumber, address, postalCode, acceptedTerms;
  let loading = false, showSuccess = false, showError = false;

  const closeDialog = e => {
    showDialog = false;
    showSuccess = false;
    showError = false;
    loading = false;

    givenName = '';
    familyName = '';
    email = '';
    phoneNumber = '';
    address = '';
    postalCode = '';
    acceptedTerms = false;

    e?.preventDefault();
  };

  const handleFormSubmit = async () => {
    showSuccess = false;
    showError = false;

    const partnerLead = new PartnerLead();
    partnerLead.givenName = givenName;
    partnerLead.familyName = familyName;
    partnerLead.email = email;
    partnerLead.phone = phoneNumber;
    partnerLead.streetAddress = address;
    partnerLead.postalCode = postalCode;

    partnerLead.sourceUrl = window.location.href;
    partnerLead.organization = organization;
    partnerLead.organizationUnit = organizationUnit;

    partnerLead.expectedFinalPrice = mortgageCalculation.expectedFinalPrice;
    partnerLead.householdIncome = mortgageCalculation.householdIncome;
    partnerLead.downpayment = mortgageCalculation.downpayment;
    partnerLead.mortgage = mortgageCalculation.mortgage;
    partnerLead.interestRate = mortgageCalculation.interestRate;
    partnerLead.calculatedInterestCost = mortgageCalculation.calculatedInterestCost;
    partnerLead.amortization = mortgageCalculation.amortization;
    partnerLead.operatingCost = mortgageCalculation.operatingCost;
    partnerLead.monthlyCost = mortgageCalculation.monthlyCost;
    partnerLead.totalCost = mortgageCalculation.totalCost;

    const apiClient = new ApiClient(basePath);
    try {
      loading = true;
      await apiClient.sendPartnerLead(partnerLead);
      showSuccess = true;
    } catch (e) {
      console.error('Ett fel uppstod när formuläret skulle skickas.', e);
      showError = true;
    } finally {
      loading = false;
    }
  };

  let firstInput = null;
  const focusFirstInput = async () => {
    await tick();
    firstInput?.focus();
  };

  $: {
    if(showDialog) {
      lock();
      focusFirstInput();
    } else {
      unlock();
    }
  }

  onDestroy(() => unlock());
</script>

<template>
  {#if showDialog}
    <section
      role="dialog"
      aria-modal="true"
      aria-labelledby="soderbergs-dialog-header"
      aria-describedby="soderbergs-dialog-description"
      id="soderbergs-dialog"
      >
      <button class="close-button" on:click={closeDialog}>
        <span class="button-text">Stäng rutan</span>
        <img src="{basePath}assets/close.svg" alt="" role="presentation">
      </button>

      <h2 id="soderbergs-dialog-header">Ansök om lånelöfte hos Söderberg & Partners Bolån</h2>

      {#if loading}
        <Loader />
      {:else if showSuccess}
        <div class="alert success" role="alert">
          <h3>Tack för din förfrågan!</h3>
          <p>Du kommer inom kort få ett samtal från Söderberg & Partners Bolån.</p>

          <button on:click={closeDialog} disabled={loading}>Stäng rutan</button>
        </div>
      {:else}
        <p id="soderbergs-ialog-description">Vill du ansöka om ett bolån eller har andra frågor? Fyll i formuläret så ringer Söderberg & Partners Bolån upp dig för vidare dialog!</p>

        <form on:submit|preventDefault={handleFormSubmit}>
          <div class="form-container">
            <div class="form-group">
              <label for="soderbergs-given-name">Förnamn:</label>
              <input id="soderbergs-given-name" type="text" autocomplete="given-name" bind:this={firstInput} bind:value={givenName} required>
            </div>

            <div class="form-group">
              <label for="soderbergs-family-name">Efternamn:</label>
              <input id="soderbergs-family-name" type="text" autocomplete="family-name" bind:value={familyName} required>
            </div>

            <div class="form-group">
              <label for="soderbergs-email">E-post:</label>
              <input id="soderbergs-email" type="email" autocomplete="email" bind:value={email} required>
            </div>

            <div class="form-group">
              <label for="soderbergs-phone">Telefonnummer:</label>
              <input id="soderbergs-phone" type="tel" autocomplete="tel" bind:value={phoneNumber} required>
            </div>

            <div class="form-group">
              <label for="soderbergs-address">Adress:</label>
              <input id="soderbergs-address" type="text" autocomplete="street-address" bind:value={address} required>
            </div>

            <div class="form-group">
              <label for="soderbergs-postal-code">Postnummer:</label>
              <input id="soderbergs-postal-code" type="text" autocomplete="postal-code" bind:value={postalCode} required>
            </div>
          </div>

          <div class="accept-container">
            <label>
              <input type="checkbox" required aria-required="true" bind:checked={acceptedTerms}>
              <span>Jag har läst och godkänt <a href="{privacyPolicyUrl}" target="_blank">villkoren för personuppgiftshantering</a>.</span>
            </label>
          </div>

          {#if showError}
            <div class="alert error" role="alert">
              <h3>Något gick fel!</h3>
              <p>Din ansökan har inte gått iväg. Du kan försöka igen senare eller själv kontakta <a href="https://www.soderbergpartners.se/bolan/" target="_blank">Söderberg & Partners Bolån</a>.</p>
            </div>
          {/if}

          <div class="form-footer">
            <img src="{basePath}assets/soderbergs-bolan.svg" alt="Söderberg & Partners Bolån">

            <button type="submit">Kontakta mig!</button>
          </div>
        </form>
      {/if}
    </section>
    <div class="overlay" on:click={closeDialog}></div>
  {/if}
</template>

<style lang="scss">
  section {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 10001;
    transform: translate(-50%, -50%) perspective(1px) translate3d(0, 0, 0);

    width: calc(100vw - 32px);
    max-width: 700px;
    max-height: calc(100vh - 32px);
    overflow-y: auto;
    padding: 24px;
    box-sizing: border-box;

    background-color: $background-color;
  }

  h2 {
    margin-top: 0;
    font-weight: 400;
  }

  .overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    transition: opacity 0.2s;
    z-index: 10000;
  }

  .close-button {
    position: absolute;
    top: 24px;
    right: 24px;

    width: 22px;
    height: 22px;

    margin: 0;
    padding: 0;

    background: transparent;
    border: 0;

    .button-text {
      @include visually-hidden;
    }

    img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }

  form {
    .form-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 16px;

      .form-group {
        flex: 1 1 100%;

        label {
          display: block;
        }

        input {
          font-family: $font-family;
          font-size: 18px;
          height: 32px;
          border: 1px solid #ccc;
          padding: 4px;
          margin: 0 -4px;
          width: 100%;
        }
      }
    }

    .accept-container {
      margin: 16px 0;

      label {
        display: flex;
        gap: 10px;
      }

      input {
        width: 18px;
        height: 18px;
      }
    }

    .form-footer {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;
      gap: 16px;

      img {
        height: 100%;
        max-height: 40px;
      }

      button {
        width: 160px;
        height: 48px;

        background-color: $primary-color;
        color: $primary-color-foreground;

        font-size: 16px;
        border: 0;
        border-radius: 8px;

        cursor: pointer;
      }
    }
  }

  .alert {

    h3 {
      margin-bottom: 0;
    }
    p {
      margin-top: 0;
    }

    button {
      width: 160px;
      height: 48px;

      background-color: $primary-color;
      color: $primary-color-foreground;

      font-size: 16px;
      border: 0;
      border-radius: 8px;
    }
  }

  @media (min-width: 768px) {
    section {
      padding: 32px;
    }
    form {
      .form-container {
        .form-group {
          flex: 1 1 45%;
        }
      }

      .form-footer {
        button {
        width: 160px;
          font-size: 20px;
        }
      }
    }
  }
</style>
