/**
 * Disable scrolling of an element by setting overflow to "hidden".
 *
 * @param {HTMLElement} element The element to disable scrolling for. Defaults to document.body.
 */
export let lock = (element = document.body) => element.style.overflow = 'hidden';

/**
 * Re-enable scrolling of an element removing "hidden".
 *
 * @param {HTMLElement} element The element to re-enable scrolling for. Defaults to document.body.
 */
export let unlock = (element = document.body) =>  element.style.overflow === 'hidden' ? element.style.overflow = '' : void 0;
