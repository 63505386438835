/**
 * Mininum amortization is calculated as follows:
 * - If the mortgage is 50-70% of the final price, 1% of the mortgage needs to be amortized per year
 * - If the mortgage is more then 70% of the final price, 2% of the mortgage neds to be amortized per year
 * - If the mortgage is more then 4,5 times the household yearly income, another 1% of the mortgage neds to be amortized per year
 *
 * @param {number} mortgage The mortgage amount.
 * @param {number} finalPrice The final price.
 * @param {number} householdIncome The total household income.
 * @returns {number} The calculated minimum amortization according to the rule set.
 */
export let calculateMinimumAmortization = (mortgage, finalPrice, householdIncome) => {
  const mortgagePercent = mortgage / finalPrice;

  let percentage = 0;
  if(mortgagePercent > 0.700001) {
    percentage = 2;
  } else if(mortgagePercent > 0.500001) {
    percentage = 1;
  }

  if(mortgage > (householdIncome * 12) * 4.5) {
    percentage++;
  }

  return mortgage * (percentage / 100) / 12;
};
