/**
 * Returns a number whose value is limited to the given range.
 *
 * @param {number} value The value to clamp.
 * @param {number} min The lower boundary of the output range.
 * @param {number} max The upper boundary of the output range.
 * @returns {number} A number in the range [min, max].
 */
export let clamp = (value, min, max) => Math.min(Math.max(value, min), max);

/**
 * Returns a number rounded to the nearest roundTo value.
 *
 * @param {number} value The value to round.
 * @param {number} roundTo The value to use as base for the rounding operation.
 * @returns {number} A number rounded to the nearest roundTo value.
 */
export let roundToNearest = (value, roundTo) => Math.round(value / roundTo) * roundTo;

/**
 * Returns a number rounded upwards to the nearest roundTo value.
 *
 * @param {number} value The value to round.
 * @param {number} roundTo The value to use as base for the rounding operation.
 * @returns {number} A number rounded upwards to the nearest roundTo value.
 */
export let roundUpToNearest = (value, roundTo) => Math.ceil(value / roundTo) * roundTo;

/**
 * Returns a number rounded downwards to the nearest roundTo value.
 *
 * @param {number} value The value to round.
 * @param {number} roundTo The value to use as base for the rounding operation.
 * @returns {number} A number rounded downwards to the nearest roundTo value.
 */
export let roundDownToNearest = (value, roundTo) => Math.floor(value / roundTo) * roundTo;
