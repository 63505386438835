<script>
  import ValueSlider from './ValueSlider.svelte';
  import SoderbergsColumn from './SoderbergsColumn.svelte';
  import SoderbergsDialog from './SoderbergsDialog.svelte';
  import MortgageCalculation from '../models/mortgage-calculation';

  import { clamp, roundToNearest, roundUpToNearest, roundDownToNearest } from '../utils/number-utils';
  import { currencyFormatter, percentageFormatter } from '../utils/formatters';
  import { calculateMinimumAmortization } from '../utils/calculators';

  // initial values configured by settings @ S&P Bolån
  export let initialInterestPercentage = 2.42;
  export let initialDownpaymentPercentage = 25.0;

  // Properties relating to the estate
  export let expectedFinalPrice, operatingCost, monthlyCost, streetAddress;

  export let expectedFinalPriceStep = 10_000;
  export let downPayment = expectedFinalPrice * (initialDownpaymentPercentage / 100);
  export let mortgage = 2_650_000;
  export let interestPercentage = initialInterestPercentage;
  export let householdIncome = roundUpToNearest(mortgage / 4.4 / 12, 1_000);
  export let householdIncomeMax = 250_000;

  // Properties that are settings for the component
  export let organization = '', organizationUnit = '', privacyPolicyUrl = '/', basePath = '/';

  export let showDebug = false;
  // Multiplicator for "Ränteavdrag"
  let interestDeductionMultiplier = 0.7;

  expectedFinalPrice = roundUpToNearest(expectedFinalPrice, expectedFinalPriceStep);

  let expectedFinalPriceMin = roundUpToNearest(expectedFinalPrice * 0.5, expectedFinalPriceStep);
  let expectedFinalPriceMax = roundDownToNearest(expectedFinalPrice * 2, expectedFinalPriceStep);

  let householdIncomeStep = 1_000;
  let householdIncomeMin = 0;

  let downPaymentStep = 10_000;
  let downPaymentValue = downPayment;
  $: {
    downPaymentValue = roundToNearest(clamp(downPayment, downPaymentMin, downPaymentMax), downPaymentStep);
    recalculateMortgage();
    recalculateAmortization();
  }

  $: downPaymentMin = roundUpToNearest(expectedFinalPrice * 0.15, downPaymentStep);
  $: downPaymentMax = roundUpToNearest(expectedFinalPrice, downPaymentStep);
  $: downpaymentPercentage = clamp((downPaymentValue / expectedFinalPrice) * 100, 15, 100);

  let mortgageStep = 10_000;
  let mortgageValue = mortgage;
  $: {
    mortgageValue = roundToNearest(clamp(mortgage, mortgageMin, mortgageMax), mortgageStep);
    householdIncome = roundUpToNearest(mortgageValue / 4.49 / 12, 1_000)
    recalculateDownpayment();
    recalculateAmortization();
  }
  $: mortgageMin = 0;
  $: mortgageMax = expectedFinalPrice * 0.85;

  let interestStep = 0.01;
  let interestMin = 0.0;
  let interestMax = 15.0;

  let amortizationStep = 1;
  let amortization = 0;
  $: amortizationValue = roundToNearest(clamp(amortization, amortizationMin, amortizationMax), amortizationStep);
  $: amortizationMax = mortgageValue === 0 ? 0 : roundUpToNearest(expectedFinalPrice * 0.03 / 12 + 10_000, 1_000);

  let amortizationMin = 0;
  $: {
    amortizationMin = roundUpToNearest(calculateMinimumAmortization(mortgageValue, expectedFinalPrice, householdIncome), amortizationStep);
    recalculateAmortization();
  }

  const recalculateMortgage = () => {
    mortgage = expectedFinalPrice - downPaymentValue;
    mortgageValue = roundToNearest(clamp(mortgage, mortgageMin, mortgageMax), mortgageStep);
  }

  const recalculateDownpayment = () => {
    downPayment = expectedFinalPrice - mortgageValue;
    downPaymentValue = roundToNearest(clamp(downPayment, downPaymentMin, downPaymentMax), downPaymentStep);
    downpaymentPercentage = clamp((downPaymentValue / expectedFinalPrice) * 100, 15, 100);
  }

  const recalculateAmortization = () => {
    amortization = amortizationMin;
    amortizationValue = amortizationMin;
  }

  $: interestDecimal = interestPercentage / 100;
  $: calculatedInterest = ((interestDecimal * mortgage) / 12) * interestDeductionMultiplier;

  $: total = calculatedInterest + amortizationValue + operatingCost + monthlyCost;

  let showDialog = false;
  const openDialog = () => {
    showDialog = true;
  }

  const mortgageCalculation = new MortgageCalculation();
  $: {
    mortgageCalculation.expectedFinalPrice = expectedFinalPrice;
    mortgageCalculation.householdIncome = householdIncome;
    mortgageCalculation.downpayment = downPaymentValue;
    mortgageCalculation.mortgage = mortgageValue;
    mortgageCalculation.interestRate = interestPercentage;
    mortgageCalculation.calculatedInterestCost = calculatedInterest;
    mortgageCalculation.amortization = amortizationValue;
    mortgageCalculation.operatingCost = operatingCost;
    mortgageCalculation.monthlyCost = monthlyCost;
    mortgageCalculation.totalCost = total;
  }
</script>

<section class="notranslate">
  <form on:submit|preventDefault={openDialog}>
    <legend>Bolånekalkylator</legend>
    <div class="summary">
      <div class="header">{streetAddress}</div>
      <div class="column-container">
        <div class="column">
          <div class="summarized-total">
            <span class="label">Din kommande månadsutgift:</span>
            <span class="value">{currencyFormatter(total)}</span>
          </div>
        </div>

        <div class="column">
          <div class="property-container">
            <span class="label">Räntekostnad <span class="subtext">(efter avdrag)</span></span>
            <span class="value">{currencyFormatter(calculatedInterest)}</span>
          </div>
          <div class="property-container">
            <span class="label">Amortering</span>
            <span class="value">{currencyFormatter(amortizationValue)}</span>
          </div>
          <div class="property-container">
            <span class="label">Driftkostnad</span>
            <span class="value">{currencyFormatter(operatingCost)}</span>
          </div>
          <div class="property-container">
            <span class="label">Avgift</span>
            <span class="value">{currencyFormatter(monthlyCost)}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="column-container slider-container">
      <div class="column">
        <ValueSlider
          id="expected-final-price"
          showDebug={showDebug}
          min={expectedFinalPriceMin}
          max={expectedFinalPriceMax}
          bind:value={expectedFinalPrice}
          actualValue={expectedFinalPrice}
          step={expectedFinalPriceStep}
          valueFormatter={currencyFormatter}
          label="Uppskattat slutpris" />

        <ValueSlider
          id="household-income"
          showDebug={showDebug}
          min={householdIncomeMin}
          max={householdIncomeMax}
          bind:value={householdIncome}
          actualValue={householdIncome}
          step={householdIncomeStep}
          valueFormatter={currencyFormatter}
          valueSuffix="/mån"
          label="Hushållets inkomst"
          labelSubtext="(före skatt/månad)" />

        <ValueSlider
          id="down-payment"
          showDebug={showDebug}
          min={downPaymentMin}
          max={downPaymentMax}
          bind:value={downPayment}
          actualValue={downPaymentValue}
          step={downPaymentStep}
          percentageInfo={downpaymentPercentage}
          valueFormatter={currencyFormatter}
          label="Kontantinsats" />
      </div>

      <div class="column">
        <ValueSlider
          id="mortgage"
          showDebug={showDebug}
          min={mortgageMin}
          max={mortgageMax}
          bind:value={mortgage}
          actualValue={mortgageValue}
          step={mortgageStep}
          valueFormatter={currencyFormatter}
          label="Bolån" />

        <ValueSlider
          id="interest"
          showDebug={showDebug}
          min={interestMin}
          max={interestMax}
          bind:value={interestPercentage}
          actualValue={interestPercentage}
          step={interestStep}
          valueFormatter={percentageFormatter}
          label="Ränta" />

        <ValueSlider
          id="amortization"
          showDebug={showDebug}
          min={amortizationMin}
          max={amortizationMax}
          bind:value={amortization}
          actualValue={amortizationValue}
          step={amortizationStep}
          valueFormatter={currencyFormatter}
          label="Amortering/månad"
          usePollingValue={true} />
      </div>

      <div class="column">
        <SoderbergsColumn basePath={basePath} />
        <SoderbergsDialog bind:showDialog={showDialog} mortgageCalculation={mortgageCalculation} privacyPolicyUrl={privacyPolicyUrl} basePath={basePath} organization={organization} organizationUnit={organizationUnit} />
      </div>
    </div>
  </form>

  {#if showDebug}
    <pre id="bolane-debug">
      expectedFinalPrice: {expectedFinalPrice}
      expectedFinalPriceMin: {expectedFinalPriceMin}
      expectedFinalPriceMax: {expectedFinalPriceMax}

      householdIncome: {householdIncome}
      householdIncomeMin: {householdIncomeMin}
      householdIncomeMax: {householdIncomeMax}

      downPayment: {downPayment}
      downpaymentPercentage: {downpaymentPercentage}
      downPaymentValue: {downPaymentValue}
      downPaymentMin: {downPaymentMin}
      downPaymentMax: {downPaymentMax}

      mortgage: {mortgage}
      mortgageValue: {mortgageValue}
      mortgageMin: {mortgageMin}
      mortgageMax: {mortgageMax}

      interest: {interestPercentage}
      interestMin: {interestMin}
      interestMax: {interestMax}

      amortization: {amortization}
      amortizationMin: {amortizationMin}
      amortizationMax: {amortizationMax}

      interestDecimal: {interestDecimal}
      calculatedInterest: {calculatedInterest}
      downPaymentValue/mortgage-diff: {downPaymentValue + mortgageValue - expectedFinalPrice}
      total: {total}
    </pre>
  {/if}
</section>

<style lang="scss">
  section {
    text-align: initial;

    max-width: 100%;
    margin: 0 auto;
    font-family: $font-family;
  }

  form {
    background-color: $background-color;

    legend {
      @include visually-hidden;
    }
  }

  .summary {
    background-color: $primary-color;
    color: $primary-color-foreground;
    margin-bottom: 24px;
    padding: 24px;
    padding-top: 0;

    .header {
      max-width: $global-max-width;
      margin: 0 auto;
      padding: 16px 0;

      font-weight: 700;
      font-size: $font-size-header;
      letter-spacing: 2px;
      text-transform: uppercase;
      text-align: center;
    }

    .column-container {
      max-width: $global-max-width;
      margin: 0 auto;
    }

    .summarized-total {
      text-align: center;
      line-height: normal;

      .label {
        display: inline-block;
        width: 100%;
        text-transform: uppercase;
        font-size: $font-size-sum-label;
        letter-spacing: 1px;
      }

      .value {
        font-size: $font-size-sum-value;
        font-weight: 700;
      }
    }
  }

  .column-container {
    display: flex;
    flex-direction: column;
  }

  .slider-container {
    max-width: $global-max-width;
    margin: 0 auto;
    padding: 0 24px;
    box-sizing: border-box;
  }

  .property-container {
    display: flex;
    flex-direction: row;;
    width: 100%;
    padding-bottom: 3px;
    font-weight: 300;
    letter-spacing: 2px;

    > .label {
      width: 70%;

      font-size: $font-size-property-label;
      .subtext {
        font-style: italic;
      }
    }

    > .value {
      width: 30%;
      font-size: $font-size-property-value;
      font-weight: 500;
      text-align: right;
    }
  }

  @media (min-width: 1024px) {
    .summary {
      .header,
      .summarized-total {
        text-align: left;
      }
    }

    .column-container {
      flex-direction: row;
      gap: 24px;

      .column {
        flex: 1 0;
      }
    }
  }
</style>
