<script>
  export let basePath = '/';
</script>

<template>
  <div class="container">
    <img src="{basePath}assets/soderbergs-bolan.svg" alt="Söderberg & Partners">

    <p>
      Hos oss får du en kundansvarig som hjälper till med samtliga delar av ditt
      bolån &ndash; från ansökan till dess att lånet är utbetalt.
    </p>

    <button>Ansök om lånelöfte!</button>
  </div>

</template>

<style lang="scss">
  .container {
    padding: 16px;
    box-sizing: border-box;
    text-align: center;

    img {
      max-width: 320px;
    }

    p {
      max-width: 75ch;
      margin: 16px auto;
      line-height: 24px;
    }

    button {
      width: 100%;
      max-width: 300px;
      height: 48px;

      background-color: $primary-color;
      color: $primary-color-foreground;

      font-size: 20px;
      border: 0;
      border-radius: 8px;

      cursor: pointer;
    }
  }
</style>
