export let upperCaseFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

export let getWords = string => string.split(/[-_\s]/g);

/**
 * Takes a string containing spaces, hyphens, and underscores and returns a camelCase string.
 *
 * @param {string} The string to camelCase.
 * @returns {string} The camelCased string.
 */
export let camelCase = string =>
  getWords(string).reduce((result, word, index) => {
    word = word.toLowerCase()
    return result + (index ? upperCaseFirstLetter(word) : word)
  }, '');

/**
 * Joins several url segments together without adding double slashes.
 *
 * @param  {...string} segments The url segments to join.
 * @returns {string} The url parts joined by /
 */
export function joinUrl(...segments) {
  return segments.join('/').replace(/(https?:\/\/)|(\/)+/g, '$1$2');
}
