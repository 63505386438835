import MortgageCalculator from './components/MortgageCalculator.svelte';
import { camelCase } from './utils/string-utils';

const currentScriptUrl = document.currentScript.src;
const basePath = currentScriptUrl.substring(0, currentScriptUrl.lastIndexOf('/') + 1);

class BolanekalkylatorElement extends HTMLElement {
  constructor() {
    super();

    const shadowRoot = this.attachShadow({ mode: 'open' });
    const container = document.createElement('div');
    shadowRoot.appendChild(container);

    const attributes = [...this.attributes]
      .map(attribute => [ camelCase(attribute.name), isNaN(attribute.value) ? attribute.value : parseFloat(attribute.value) ]);

    const options = Object
      .fromEntries(attributes);

    const stylesheet = document.createElement('link');
    stylesheet.setAttribute('rel', 'stylesheet');
    stylesheet.setAttribute('href', `${basePath}bundle.css`);
    shadowRoot.appendChild(stylesheet);

    const cssVariables = this.getAttribute('style');
    container.setAttribute('style', cssVariables);
    delete options.style;

    const requiredOptions = ['streetAddress', 'operatingCost', 'monthlyCost', 'expectedFinalPrice', 'privacyPolicyUrl', 'organization', 'organizationUnit'];
    if(!requiredOptions.every(option => typeof options[option] !== 'undefined')) {
      console.info('Missing required parameter for MortgageCalculator. Required arguments are ', requiredOptions, 'supplied options are', options);
      return;
    }

    this.instance = new MortgageCalculator({
      target: container,
      props: {
        basePath,
        ...options
      }
    });
  }
};

customElements.define('sp-bolanekalkylator', BolanekalkylatorElement);
