const currencyNumberFormat = new Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const percentageFormat = new Intl.NumberFormat('sv-SE', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  style: 'percent'
});

const percentageWithoutFractionsFormat = new Intl.NumberFormat('sv-SE', {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  style: 'percent'
});

export let currencyFormatter = (value) => {
  if(typeof value === 'string') {
    value = parseInt(value);
  }

  if(typeof value !== 'number' || isNaN(value)) {
    value = 0;
  }

  return currencyNumberFormat.format(value);
};

export let percentageFormatter = (value) => {
  if(typeof value === 'string') {
    value = parseInt(value);
  }

  if(typeof value !== 'number' || isNaN(value)) {
    value = 0;
  }

  value /= 100;

  return percentageFormat.format(value);
};

export let percentageWithoutFractionsFormatter = (value) => {
  if(typeof value === 'string') {
    value = parseInt(value);
  }

  if(typeof value !== 'number' || isNaN(value)) {
    value = 0;
  }

  value /= 100;

  return percentageWithoutFractionsFormat.format(value);
};

export let defaultFormatter = (value) => value;
