class PartnerLead {
  givenName = '';
  familyName = '';
  email = '';
  phone = '';
  postalCode = '';
  streetAddress = '';
  organization = '';
  organizationUnit = '';
  leadType = 'Bolanekalkyl';
  externalId = '';
  sourceUrl = '';
  expectedFinalPrice = 0;
  householdIncome = 0;
  downpayment = 0;
  mortgage = 0;
  interestRate = 0.0;
  calculatedInterestCost = 0;
  amortization = 0;
  operatingCost = 0;
  monthlyCost = 0;
  totalCost = 0;

  /**
   * Ensures that all the fields have the correct type and falls back to default values if not.
   */
  ensureFieldTypes() {
    const stringFields = ['givenName', 'familyName', 'email', 'phone', 'postalCode', 'streetAddress', 'organization', 'organizationUnit', 'leadType', 'externalId', 'sourceUrl'];
    for(const field of stringFields) {
      this.#ensureString(field);
    }

    const numberFields = ['expectedFinalPrice', 'householdIncome', 'downpayment', 'mortgage', 'interestRate', 'calculatedInterestCost', 'amortization', 'operatingCost', 'monthlyCost', 'totalCost'];
    for(const field of numberFields) {
      this.#ensureNumber(field);
    }
  }

  #ensureString(fieldName) {
    const fieldValue = this[fieldName];
    if(typeof fieldValue !== 'string' || fieldValue === 'undefined') {
      console.warn(`Partner lead field ${fieldName} was not a proper string. Setting to empty.`);
      this[fieldName] = '';
    }

    return true;
  }

  #ensureNumber(fieldName) {
    const fieldValue = this[fieldName];
    if(typeof fieldValue !== 'number') {
      console.warn(`Partner lead field ${fieldName} was not a number. Setting to zero.`);
      this[fieldName] = 0;
    }
  }
}

export default PartnerLead;
