import { joinUrl } from "../utils/string-utils";
import PartnerLead from "../models/partner-lead";

class ApiClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  /**
   * Sends a partner lead to the API
   *
   * @param {PartnerLead} partnerLead
   * @throws Will throw an error if the request is unsuccessful.
   */
  async sendPartnerLead(partnerLead) {
    partnerLead.ensureFieldTypes();

    const url = joinUrl(this.baseUrl, '/RA/Bolan/Services/IBolan/SendPartnerLeadAsync');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      body: new URLSearchParams(partnerLead)
    });

    if(response.status != 200) {
      console.error(`An error occured while posting a lead to ${url}.`, partnerLead);
      throw new Error(`An error occured while posting a lead to ${url}.`)
    }

    var responseJson = await response.json();
    if(!responseJson.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i)) {
      console.error(`An error occured while posting a lead to ${url}.`, partnerLead);
      throw new Error(`An error occured while posting a lead to ${url}.`)
    }
  }
}

export default ApiClient;
